@import '../../scss/mixins';

.resultInputElem {
    width: 90%;
    margin-top: 5%;

    h2 {
        font-weight: 300;
        font-size: 1.3rem;
        text-transform: uppercase;
    }

    .subjInpPair {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2% 0;

        @include respond (phone) {
            flex-wrap: wrap;
            justify-content: center;
            margin: 5% 0;
        }
    }

    .resultNameHeadElem {
        margin: 5% 0;
        font-size: 2rem;
        font-weight: 400;
        color: #00c6fb;
        text-align: center;
        text-transform: capitalize;
    }

}
