@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin gridCenter {
  display: grid;
  place-items: center;
}

@mixin elementGrid($minWidth, $gap) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($minWidth, 1fr));
  grid-gap: $gap;
}

// MEDIA QUERY MANAGER

/*
    0 - 400px:      Small Phone
    400px - 600px:  Phone
    600 - 900px:    Tablet Portrait
    900 - 1200px:   Tablet Landscape
    1200 - 1800:    Desktop | Normal Styles Spply
    1800px + :      Big Desktop
    2500px +: 4K Desktop
    
    $breakpoint argument choices:
    - phone
    - tab-port
    - tab-land
    - big-desktop
    
    ORDER: Base + typography > general layout + grid > page layout > components
    
    1em = 16px
    */
@mixin respond($breakpoint) {
  @if $breakpoint==small-phone {
    @media only screen and (max-width: 25em) {
      @content;
    }

    //400px
  }

  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }

    //600px
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }

    //900px
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }

    //1200px
  }

  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }

    //1800
  }

  @if $breakpoint==four-k {
    @media only screen and (min-width: 160em) {
      @content;
    }

    //1800
  }
}