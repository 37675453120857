.accordions {
    padding: 20px;
}

.faqEl {
    h1 {
        color: #005bea;
        text-align: center;
        margin-top: 2rem;
    }

    //button {
    //    margin-top: 20px;
    //    margin-left: 20px;
    //    //background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
    //    //backdrop-filter: blur(5px);
    //}
}
