/*.mcard .p-card .p-card-content{*/
/* padding:0*/
/*}*/

/*ul{*/
/* list-style-type: none;*/
/* !*margin: 0;*!*/
/* !*padding: 0;*!*/
/* !*width: 200px;*!*/
/* background-color: #00c6fb;*/
/*}*/
/*li a {*/
/* display: block;*/
/* !*background-color: #dddddd;*!*/
/* padding: 8px 16px*/
/*}*/
/*li a:hover{*/
/* background-color: #555;*/
/* color: white;*/
/*}*/
/*li a.active{*/
/* background-color: #00a0b4;*/
/* color: white;*/
/*}*/
