@import '../../scss/mixins';

.parentClass {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 60vw;
    @include respond (phone) {
        width: 95vw;
    }

    .cseeResultH2 {
        margin: 5% 0;
        font-size: 2rem;
        font-weight: 400;
        color: #00c6fb;
        text-align: center;
        text-transform: capitalize;
    }

    .addOptHead {
        font-weight: 500;
        color: #00c6fb;
        margin: 2rem 0;
        text-align: center;
    }

    .cseeResultH3 {
        color: #0082fb;
        font-weight: 500;
        text-align: center;
        font-size: 1.3rem;
        margin: 1rem 0;
    }

    .radios {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 2rem 0;
    }

    .radio {
        label {
            margin-left: 5px;
        }
    }

    .streamElem {
        margin-top: 5%;
        width: 100%
    }

    .optToggleElem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 2rem;
    }
}