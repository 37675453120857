.parentClass {
    .resultCards {
        align-self: flex-start;
        justify-self: flex-start;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 2%;
    }

    .resultCard {
        border: 1px solid #ccc;
        padding: 0.5em;
        border-radius: 1em;
        min-width: 30vw;
        width: fit-content;
        margin: 1%;

        h2 {
            font-weight: 400;
            font-size: 1.3rem;
            color: #005bea;
        }

        p {
            font-weight: 300;
            font-size: 1.2rem;
            color: blue;
        }
    }

    .resultPoint {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    //button {
    //    //background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
    //    //backdrop-filter: blur(5px);
    //}
}
