@import '../../scss/mixins';

.parentClass {
    .signOutClass {
        color: #fff;
    }

    .container {
        margin-top: 3rem;
        width: 100vw;
        padding: 2rem;

        button {
            background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
            backdrop-filter: blur(5px);
        }
    }

    .details {
        margin-top: 3rem;
    }

    .detail {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        @include respond (phone) {
            flex-wrap: wrap;
        }
    }

    h2 {
        font-weight: 300;
        font-size: 2.2rem;
        color: #00c6fb;
    }

    h1 {
        color: #005bea;
    }
}