@import '../../scss/mixins';

.loginElem {
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;

    .card {
        box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;
        width: 400px;
        height: 450px;
        background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
        backdrop-filter: blur(5px);
        color: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        @include respond(phone) {
            width: 80vw;
        }

        h1 {
            font-weight: 300;
            text-transform: uppercase;
        }

        //input {
        //    margin-top: 1rem;
        //    margin-bottom: 1rem;
        //}

        P {
            text-align: center;
            line-height: 1.5;
            width: 70%;
            margin: 0 auto;
        }

        label {
            font-weight: 300;
            font-size: 1.2rem;

            @include respond (phone) {
                font-size: 1.2rem;
            }
        }
    }

    .homeLogo {
        max-width: 250px;

        @include respond (phone) {
            max-width: 200px;
            height: auto;
        }
    }

    .phoneElem {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .isdCode {
        color: #495057;
        position: absolute;
        top: 40%;
        left: 5%;

        @include respond (phone) {
            top: 40%;
            left: 5%;
        }
    }
}

