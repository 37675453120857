.footerElem {
    color: rgb(48, 113, 153);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom:0
}

.footerLink {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
}
