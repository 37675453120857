@import '../../scss/mixins';

.parentClass {
    .content {
        width: 100%;
        height: 70vh;
        display: grid;
        place-items: center;
        color: #005bea;
    }

    .buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @include respond (phone) {
            flex-direction: column;
            padding: 20px;
        }
    }

    button {
        background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
        backdrop-filter: blur(5px);

        @include respond (phone) {
            margin-bottom: 10px;
            //width: fit-content;
            width: -webkit-fill-available;
        }
    }

    h1 {
        margin-bottom: 2rem;
    }
}
