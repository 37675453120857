@import '../../scss/mixins';

.contactEl {
    button {
        margin-top: 20px;
        margin-left: 20px;
        background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
        backdrop-filter: blur(5px);
    }

    h1,
    h2,
    h3 {
        color: #005bea;
        font-weight: 300;
    }

    h1,
    h2 {
        text-align: center;
        margin-top: 2rem;
    }

    h2 {
        font-size: 2rem;
        margin-bottom: 3rem;

        @include respond (phone) {
            font-size: 1.5rem;
            margin-bottom: 0;
        }
    }

    h3 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 400;
    }

    p {
        text-align: justify;
    }

    .content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        @include respond (phone) {
            padding: 20px;
        }
    }

    .details {
        max-width: 50vw;
        align-self: flex-start;

        @include respond (phone) {
            max-width: 100%;
        }

        i {
            font-size: 1.5rem;
            color: #00c6fb;
            margin: 5px;
            cursor: pointer;
        }
    }

    .contactInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include respond (phone) {
            align-items: flex-start;
        }

        a {
            text-decoration: none;
            color: #00c6fb;
        }
    }

    .map {
        iframe {
            width: 800px;

            @include respond (phone) {
                width: 100%;
            }
        }

        @include respond (phone) {
            margin-top: 2rem;
        }
    }
}