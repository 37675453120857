@import '../../scss/mixins';

.layoutElem {
    width: 100vw;
    height: 100vh;

    h1 {
        font-weight: 300;
        font-size: 3rem;

        @include respond (phone) {
            font-size: 2rem;
            text-align: center;
        }
    }
    .footerClass{
        margin-top: 2rem;
        position: relative;
        bottom: 0;
    }
}
