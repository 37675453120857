
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.error-msg {
  color: #1bff00;
}

.loader-container {
  background: rgba(255, 255, 255, 0.8);
  width: 100vw;
  height: 85vh;
  display: grid;
  place-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #005bea !important;
}

.p-datatable-header {
  text-align: center;
  text-transform: uppercase;
  color: #005bea !important;
  /*font-size: xx-large !important;*/
}

.p-column-title {
  color: #00c6fb !important;
  /*font-size: larger !important;*/
}

/*.p-card.p-component {*/
/*  padding: 1.5rem !important;*/
/*}*/

/* Data table overwrites  */
.datatable-responsive-demo {
  margin: 2%;
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody >tr >td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {

/*  .p-datatable-header {*/
/*    !*font-size: x-large !important;*!*/
/*  }*/

/*  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead>tr>th,*/
/*  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot>tr>td {*/
/*     !*display: none !important;*!*/
/*  }*/

/*  .p-datatable-thead tr {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*  }*/

/*  .p-datatable-thead tr:nth-child(1) {*/
/*    display: none;*/
/*  }*/

/*  .datatable-responsive-demo .p-datatable .p-datatable-tbody>tr {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    padding: .5rem;*/
/*  }*/

  /*.p-column-title {*/
  /*  font-size: 15px !important;*/
  /*}*/

/*  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody>tr>td span.data {*/
/*    font-size: 15px !important;*/
/*  }*/

/*  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody>tr>td {*/
/*    text-align: left;*/
/*    display: block;*/
/*    width: 100%;*/
/*    float: left;*/
/*    clear: left;*/
/*    border: 0 none;*/
/*    max-width: 45vw;*/
/*    word-break: break-word;*/
/*  }*/
/*  .cardHead{*/
/*    text-align: center;*/
/*  }*/
/*.redtext{*/
/*  color:red;*/
/*}*/
/*  .datatable-responsive-demo .p-datatable .p-datatable-responsive-demo .p-datatable-tbody>tr>td .p-column-title {*/
/*    padding: .4rem;*/
/*    min-width: 100%;*/
/*    display: inline-block;*/
/*    margin: -.4em 1em 0 -.4rem;*/
/*    font-weight: bold;*/
/*  }*/

/*  .datatable-responsive-demo .p-datatable .p-datatable-tbody>tr>td {*/
/*    padding: .3rem !important;*/
/*  }*/

/*  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody>tr>td:last-child {*/
/*    border-bottom: 1px solid var(--surface-d);*/
/*  }*/
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4em 1em -.4em -.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}

.p-column-filter,
.p-multiselect {
  width: 100%;
}

.learning-institutes .avatar-container {
  top: 20% !important;
}

/*input#phone {*/
/*  padding-left: 20%;*/
/*}*/

.grade-btn input.p-inputtext.p-component::placeholder {
  font-size: .8rem;
  display: grid;
  place-items: center;
}

.hide-desktop {
  display: none !important;
}

/*.res-table .p-paginator-pages button,*/
/*.res-table .p-paginator-bottom button {*/
/*  color: #fff !important;*/
/*}*/

/*.p-accordion-header-text,*/
/*.p-accordion-toggle-icon {*/
/*  color: #00c6fb !important;*/
/*}*/

@media only screen and (max-width: 37.5em) {
  .p-card {
    box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0 !important;
    margin-bottom: 1rem !important;
  }

  /*input#phone {*/
  /*  padding-left: 34%;*/
  /*}*/

  .hide-mobile {
    display: none !important;
  }

  .hide-desktop {
    display: block !important;
  }
}
