@import '../../scss/mixins';

.homePage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
    flex-direction: column;

    .card {
        box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;
        width: 400px;
        height: 400px;
        background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
        backdrop-filter: blur(5px);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        @include respond(phone) {
            width: 95vw;
        }
    }

    .homeLogo {
        max-width: 250px;
    }

    .option {
        margin-bottom: 10px;

        label {
            margin-left: 5px;
        }
    }

    h2 {
        text-align: center;
        font-weight: 300;
        margin-bottom: 1rem;

        span {
            font-weight: 500;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    button {
        margin: 2% 0;
    }

    p {
        text-align: center;
        padding: 0 5%;
    }

    .footerClass {
        margin-top: 2rem;
        position: fixed;
        bottom: 0;
    }
}
