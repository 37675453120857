@import '../../scss/mixins';

.parentClass {
    .container {
        width: 100%;
        height: 80vh;
        display: grid;
        place-items: center;
        color: #005bea;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
    }

    .headRow {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 95vw;

        @include respond (phone) {
            flex-wrap: wrap;
            margin-top: 10px;

        }
    }

    .progRowElem {
        justify-content: space-evenly !important;
        align-items: center !important;
        flex-direction: row !important;
        max-width: 80vw;
        margin: 0 auto;

        @include respond (phone) {
            flex-direction: column !important;
        }
    }

    .row {
        margin-bottom: .5rem;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 2rem;

        @include respond (phone) {
            padding: 1rem;
            width: 100%;
        }

        h1 {
            font-weight: 500;
            font-size: 2rem;
            color:#00c6fb;
            @include respond (phone) {
                font-size: 1.5rem;
                width: 100%;
                text-align: center;
            }
        }

        h2 {
            font-weight: 500;
            font-size: 1.3rem;
            //color: #00c6fb;

            @include respond (phone) {
                //font-size: 1.5rem;
                width: 100%;
                text-align: center;
                margin-top: 5px;
            }
        }

        i {
            background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
            padding: .5rem;
            border-radius: 50%;
            font-size: 1.2rem;
            color: #fff;
            cursor: pointer;
            width: fit-content;
            position: absolute;
            top: 0;
            left: 0;

            @include respond (phone) {
                right: 0;
                font-size: 1rem;
            }
        }

        h1,
        h2,
        i {
            text-align: center;
        }
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond (phone) {
            margin-bottom: 2rem;
        }
    }

    //button {
    //    background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
    //    backdrop-filter: blur(5px);
    //}

    .markElemBox {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 95vw;
        margin-top: 2rem;

        @include respond (phone) {
            margin-top: 0;
            margin-bottom: 2rem;
            flex-wrap: wrap;

        }

        h2 {
            font-weight: 500;
            font-size: 1.6rem;
            color: #005bea;
            text-align: center;
            margin-bottom: 1rem;
        }

        h3 {
            font-weight: 500;
            font-size: 1.5rem;
            color: #00c6fb;

            @include respond (phone) {
                //font-weight: 300;
            }
        }

        p {
            color: #005bea;
            font-weight: 500;
            font-size: 1.2rem;
        }
    }

    .markRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .markCol {
        min-width: 300px;
        width: fit-content;
        h2 {
            font-weight: 500;
            font-size: 1.5rem;
            color: black;

            @include respond (phone) {
                //font-weight: 400;
            }
        }
    }
}
