@import '../../scss/mixins';

.parentClass {

    .container {
        width: 100%;
        min-height: 70vh;
        display: grid;
        place-items: center;
    }

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .buttons {
        width: 60vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    .inputs {
        display: flex;
        justify-content: space-around;
        align-items: center;

        input {
            margin: 1rem 0;
            width: 40%;
        }
    }

    h1 {
        color: #005bea;
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    h2 {
        color: #00c6fb;
        font-weight: 400;
        text-align: center;
        margin: 1rem 0;
    }

    //button {
    //    //background-color: rgba(255, 255, 255, .15);
    //    background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
    //    backdrop-filter: blur(5px);
    //}

    input {
        width: 100%;
    }

    .optionalSubElem {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 2rem 0;
        width: 100%;
        //min-height: 420px;
    }

    .optionalSubHead {
        font-weight: 500;
        color: #00c6fb;
        margin: 2rem 0;
        text-align: center;
    }

    .contentDiv {
        width: 60vw;

        @include respond (phone) {
            width: 95vw;
        }
    }

}
