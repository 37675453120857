@import '../../scss/mixins';

.gradeInputElem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;

    @include respond (phone) {
        margin-left: 0;
        //margin-top: 15px;
    }

    input {
        width: 8rem !important;
        margin-left: 5%;
        border-radius: 2rem;
        @include respond (phone) {
            width: 5rem !important;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .gradeBtn {
        margin: 0 10px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}
