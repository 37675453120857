@import '../../scss/mixins';

.progressItems {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include respond (phone) {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .progressItem {
        display: flex;
        align-items: center;

        hr {
            width: 20px;
            border: 1px solid;

            @include respond (phone) {
                display: none;
            }
        }
    }

    .count {
        border: 1px solid;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        margin-right: 6px;
        margin-left: 6px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
    }

    .label {
        margin-right: 6px;
    }
}