@import '../../scss/mixins';

.headerElem {
    //position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    .headerNav {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: center;
        padding: 18px 0 12px;
        //background-color: rgba(255, 255, 255, .15);
        background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
        backdrop-filter: blur(5px);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .16);
    }

    .container {
        width: 90vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond (phone) {
            flex-wrap: wrap;
        }
    }

    .headerLogo {
        max-width: 300px;

        @include respond (phone) {
            margin-bottom: 10px;
            max-width: 220px;
            height: auto;
        }
    }

    .progressElem {
        max-width: 60vw;
        width: 100%;
    }

    .avatarContainer {
        @include respond (phone) {
            //position: absolute;
            top: 10%;
            right: 5%;
        }
    }
}
